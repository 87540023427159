export default {
  /*
    https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  */

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  },
  findLastIndex(array, condition) {
    let foundIndex, index = array.length - 1;
    for ( ; index >= 0; index--) {
      if (condition && condition(array[index])) {
        foundIndex = index;
        break;
      }
    }
    return foundIndex || -1;
  },
  partialShuffle(array, start, end, shuffleFunc) {
    let partial = array.splice(start, end - start);
    shuffleFunc(partial);
    array.splice(start, 0, ...partial);
  },
  partialSort(array, start, end, sortFunc) {
    let partial = array.splice(start, end - start);
    partial.sort(sortFunc);
    array.splice(start, 0, ...partial);
  },
  distributeArray(rootArray, distributingArray, start_number = 1) {
    if (distributingArray.length === 0 || rootArray.length === 0) return rootArray;

    let temp = [];
    let validSpotForDistributing = Math.floor(rootArray.length / distributingArray.length);

    if (!validSpotForDistributing) return rootArray;

    for (let index = 0; index < rootArray.length; index++) {
      if (index >= start_number && (index - start_number) % validSpotForDistributing === 0 && distributingArray.length > 0) {
        const ad = distributingArray.shift();
        if (ad) temp.push(ad);
      }
      temp.push(rootArray[index]);
    }

    return temp;
  }
}
